import Vue from "vue";
import {
    Button,
    Popup,
    Toast,
    Uploader,
    ActionSheet,
    PullRefresh,
    RadioGroup,
    Radio,
    Cell,
    CellGroup,
    Skeleton,
    List,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Notify,
    Icon,
    ShareSheet,
    Cascader,
    Dialog,
    Field,
    Picker,
    Search,
    Image as VanImage,
    SwipeCell,
    Popover,
    Grid,
    GridItem,
    Tabbar,
    TabbarItem,
    Loading,
    Overlay,
    Empty,
    Badge,
    Steps,
    Step,
    NoticeBar
} from "vant";
import "vant/lib/index.css";

Vue.use(Button); //按钮
Vue.use(Popup); //弹出层
Vue.use(Toast); //提示
Vue.use(Uploader); //上传
Vue.use(ActionSheet); //菜单
Vue.use(PullRefresh); //下拉刷新
Vue.use(List); //上拉加载
Vue.use(Skeleton); //骨架屏
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Notify);
Vue.use(Icon);
Vue.use(ShareSheet);
Vue.use(Cascader);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Search);
Vue.use(VanImage);
Vue.use(SwipeCell);
Vue.use(Popover);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Empty);
Vue.use(Badge);
Vue.use(Steps);
Vue.use(Step);
Vue.use(NoticeBar);

