import Exposure from './modules/exposure'
import Browse from './modules/browse'
import Click from './modules/click'

// 实例化曝光、浏览和点击
// const bro = new Browse();
// const exp = new Exposure()
// const cli = new ClickEvent()
Exposure.init()

const Events = {
    'browse': Browse,
    'click': Click,
    'exposure': Exposure,
}
/**
 *  * 埋点唯一标识 通过参数传入(必填)。
 *  用于区分同一个元素上绑定不同事件上报，如：同时绑定曝光和点击事件
 *  browseDistinguish | clickDistinguish | exposureDistinguish
 * 
 *  点击事件带参数
 *  <div v-track:click="{clickDistinguish: 'FlashSalesSkuClick'}"></div>
 *  
 *  页面曝光事件带参数
 *  <div v-track:browse="{goods_id: '1'}"></div>
 *  
 *  元素浏览事件并点击带参数
 *  <div v-track:click|exposure="{goods_id: '1'}"></div>
 * 
 *  元素浏览带参数
 *  <div v-track:exposure="{goods_id: '1'}"></div>
 * 
 *   2021-09-28 create by zhi.xie@yiwill.com
 * @author xiezhi
 * @todo 异步数据监听、延迟上报等
 */
const Track = {
    // 调用指令声明周期钩子函数bind
    bind(el, binding, { context, componentInstance}) {
        // 获取指令参数
        const { arg, modifiers, value } = binding;
        arg.split('|').forEach(item => {
            if(Events[item]) {
                Events[item].add({ el, value, context, modifiers })
            }
            // switch (item) {
            // case "click":
            //     Click.add({ el, value })
            //     break;
            // case "exposure":
            //     Exposure.add({ el, value })
            //     break;
            // case "browse":
            //     Browse.add({ el, value, context, modifiers  })
            //     break;
            // default:
            // }
        })
    },
    componentUpdated(el, binding, vnode, oldVnode){
        // TODO: VNode更新重新绑定指令
    },
    unbind(el, binding) {
        // 移除点击监听
        Click.removeListener(el);
        el.$unwatch && el.$unwatch()
    }
}

export default Track;

