import { isEgateeApp, isJdeApp, isAndroid, isFlutter } from './env'
import { toParamStr } from './format'
// import { getGlobalConfig } from '../project/index'
// import { trackingFn } from '@utils/project'

// 定义Android提供的函数名
const ANDROID_FUNCTIONS = {
    /**
     * 跳转到购物车页面 无参数
     */
    goToCart: "toCartActivity",
    /**
     * 跳转到商详页
     * @param (spuId, skuId)
     */
    goToProductDetail: "toGoodsDetailActivity",
    /**
     * 加购打开App多规格弹窗
     * @param (spuId, skuId)
     */
    addToCart: "showAddToCartDialog",
    /**
     * 获取国家货币符号
     */
    getCountrySymbol: "getCountryCurrencySymbol",
    /**
     * 发送埋点数据
     * @param jsonData
     */
    athenaEvent: "athenaEvent",
    /**
     * android返回
     */
    back: "back",

    /**
     * webview借助android唤醒
     * content: url
     */
    openWhatsApp: "openWhatsApp",

    /**
     * webview借助android唤醒 并指定手机号
     * @param content: url
     * @param phoneNumber: 手机号
     */
    openWhatsAppNew: "openWhatsAppNew",

    /**
     * JdeApp选择门店
     */
    selectRetailer: "selectRetailer",
    /**
     *  app拨打电话
     * @param string 电话号码
     */
    callTel: "callTel",
    /**
     *  跳转app首页
     * @param {string} pageId 页面id
     */
    goAppHome: "go2HomeActivity",
    /**
    *  关闭当前webview
    */
    finishWeb: "finishWeb",

    /**
     * 查询app系统通知权限是否开启
     */
    getAppNotificationPermission: "getAppNotificationPermission",

    /**
     * 点击跳转到app权限设置页
     */
    jumpToAppSetting: "jumpToAppSetting"
}
// IOS 提供方法
const IOS_FUNCTIONS = {
    /**
     * @description 是否获得相机权限 、返回成功、失败。
     * @returns {Boolean} true / false
     */
    isGrantedCamera: 'isGrantedCamera',
    /**
     * @description 是否获得相册权限 、返回成功、失败。
     * @returns {Boolean} true / false
     */
    isGrantedPhotos: 'isGrantedPhotos'
}
const HYBIRD_INTERFACE_METHODS = {
    androidInterface: 'android',
    flutterInterface: 'flutter_inappwebview'
}
const PAGES = {
    productDetail: "/wap/tmpl/product_detail.html",
    receiveAreaSelect: "/wap/tmpl/app_receive_area_select.html",
    appLogin: "/wap/tmpl/app_login.html"
}
/**
 * 调用Android工具类
 * const hybrid = HybridHandler.getInstance()
 */
class HybridHandler{
    // 所有可提供调用的函数
    FUNCTIONS = {
        ...ANDROID_FUNCTIONS,
        ...IOS_FUNCTIONS
    };
    #hybirdInterfaceMethods = HYBIRD_INTERFACE_METHODS;
    // 跳转Android的目标页面
    pages = PAGES;
    constructor(){
    }
    // 单例
    static getInstance() {
        if (!HybridHandler.instance){
            HybridHandler.instance = new HybridHandler();
        }
        return HybridHandler.instance;
    }
    registerAndroidCallback(fn, callbackFnName = 'appCallBack') {
        let androidInterface = this.#hybirdInterfaceMethods.androidInterface
        if (window[androidInterface] && typeof fn === 'function') {
            window[androidInterface][callbackFnName] = fn
        }
    }
    // 执行Android提供的函数
    execAndroidFn (fn, ...params) {
        return new Promise((resolve, reject) => {
            try {
                if (!isEgateeApp() && !isJdeApp()) {
                    return reject('It can only be used in Egatee App!');
                }

                let res = this.callHybridInterfaceMathod(this.#hybirdInterfaceMethods[isFlutter() ? 'flutterInterface' : 'androidInterface'], fn, ...params );
                if (res === false){
                    return reject(`Failed to call the Android method: [${fn}]`);
                }

                // flutter_inappwebview.callHandler 返回的是 promise
                if(res && typeof res.then === 'function') {
                    res.then((data) => {
                        console.log('[CallAppFn][Result]::', data)
                        resolve(data)
                    }).catch((err) => {
                        reject(err)
                    })
                } else {
                    console.log('[CallAppFn][Result]::', res)
                    resolve(res)
                }
            } catch (error) {
                console.log("[execAndroidFn][error]::", error);
                reject(error)
            }
        })
    }
    /**
     * App内嵌页面 url跳转页面 App拦截url
     * @param {*} url
     * @param {*} params
     * @returns
     */
    jumpToAndroidUrl(url, params){
        if (!isEgateeApp() && !isJdeApp()){
            console.warn("It can only be used in Egatee App!");
            return ;
        }
        if (Object.values(this.pages).indexOf(url) === -1){
            throw new Error(`The target page [${url}] does not exist`);
        }
        let targetUrl = url;
        if(params){
            targetUrl += '?' + toParamStr(params);
        }
        location.href = targetUrl;
    }

    /**
     * 协议唤醒App，暂时不可用
     * @param {*} { store } next ctx or { redux store }
     * @param {*} options 配置参数，详情请参考如下github
     * @param {*} params
     * @github https://github.com/suanmei/callapp-lib
     */
    // callApp() {
    //     let {store} = arguments[0]
    //     let options = arguments[1]
    //     let params = arguments[2]
    //     let trackPrams = arguments[3]
    //     let toGooglePlayParams = arguments[4]

    //     // 暂只支持Android端唤醒，ios端后续在考虑. xiezhi 22-08-19
    //     if (!isAndroid({ store })) {
    //         console.error("Sorry, please open it on Android terminal!")
    //         return;
    //     }

    //     // 获取配置文件中的package
    //     // const config = getGlobalConfig({ store })
    //     const config = {}

    //     //
    //     const appPackage = config['APP_PACKAGE'];
    //     // app google play 包名
    //     const appOfflinePackage = config['APP_OFFLINE_PACKAGE'];

    //     // 组合配置
    //     let _options = Object.assign({
    //         scheme: {
    //             protocol: "simbaegatee",
    //             host: "simba.egatee.com"
    //         },
    //         // android 唤醒必须
    //         intent: {
    //             scheme: "simbaegatee",
    //             package: appPackage//"com.egatee.store.test"
    //         },
    //         appstore: "",
    //         // 失败后跳google Play
    //         fallback: `https://play.google.com/store/apps/details?id=${appOfflinePackage}${toGooglePlayParams}`,
    //         logFunc: (status) => { //'pending' 'failure'
    //             let result = ''
    //             status === 'failure' ? result = 'Fail' : result = ''

    //             // 埋点-上报唤起app结果 Fail为跳谷歌，Success为唤起Egatee
    //             // trackPrams && trackingFn(trackPrams?.event_name || '', {
    //             //     distinguish_id: trackPrams?.distinguish_id || '',
    //             //     member_id: trackPrams?.member_id || '-1',  // 获取不到userId 默认-1
    //             //     member_type: trackPrams?.member_type || '',
    //             //     page: trackPrams?.page || '',
    //             //     result
    //             // }, 'img', {store})
    //         },
    //         timeout: 2000,
    //     }, options)

    //     // 组合参数
    //     let p = Object.assign({
    //         path: "",
    //         param: {}
    //     }, params)

    //     // 唤醒
    //     import('callapp-lib').then((_CallApp) => {
    //         const CallApp = _CallApp.default
    //         const callApp = new CallApp(_options)

    //         // console.log("====", callApp.generateScheme(p))
    //         console.log("[callApp][androidIntent]::", callApp.generateIntent(p))
    //         callApp.open(p)
    //     })
    // }
    // #+方法名创建私有方法，ES2020语法。babel最终编译成通过weakSet为对象设置私有方法。
    callHybridInterfaceMathod(HybridInterface, methodName, ...args){
        if (window[HybridInterface]) {
            if (typeof window[HybridInterface][methodName] === 'function') {
                return window[HybridInterface][methodName](...args);
            } else if (typeof window[HybridInterface]['callHandler'] === 'function') {
                console.log('[CallFlutterHandler]->event_name:', methodName, ', params:', ...args)
                // flutter调用方式。
                return window[HybridInterface]['callHandler'](methodName, ...args)
            } else {
                console.warn(`The ${HybridInterface}.${methodName} Methods of Egatee App does not exists`);
            }
        } else {
            console.warn('not Egatee app enviroments');
        }
        return false;
    }
}
export default HybridHandler;