import { toParamObj } from '@/utils/track/eventHandler'
import { eventCommonFields } from '@/utils/track/eventTypes'
import HybridHandler from '@/utils/common/hybridHandler'

// 发送数据
export const send = (params) => {
    let data = JSON.stringify(params)
    // console.log("[Big Data][send]::", data)
    const hybrid = HybridHandler.getInstance()
    hybrid.execAndroidFn(hybrid.FUNCTIONS.athenaEvent, data).then(res=>{
        // console.log("success", res)
    }).catch(err=>{
        console.error(err)
    })
}

/**
 * 处理点击事件公共参数
 * @param {*} param 
 * @returns 
 */
export function getSendParam(param, eventType){
    if (!param) {
        return {}
    }
    let type = typeof param;
    if (type == "string"){
        param = toParamObj(param)
    }
    param[eventCommonFields.eventType] = eventType;
    param[eventCommonFields.createTime] = Date.now();
    return param;
}
