import observeExposure from "./observeExposure.js";
import onVisible from "./onVisible.js";
import nl2br from './nl2br.js'
import Track from "./vTrack/index.js";

const CustomerDirectives = {
    install(Vue) {
        Vue.directive("observe-exposure", observeExposure);
        Vue.directive("on-visible", onVisible);
        Vue.directive("nl2br", nl2br);
        Vue.directive("track", Track)
    }
}

export default CustomerDirectives