// 埋点上报信息
export const eventType = {
    // 埋点事件标识
    click: "egatee_common_click",
    exposure: "egatee_common_exposure",
    // browse: "h5egatee_common_browse"
    browse: "egatee_common_exposure"
}

// 埋点上报数据字段
export const eventCommonFields = {
    eventType: "event_type", // 埋点事件类型
    distinguishId: "distinguish_id", // 标识符，区分唯一埋点实例 字符串
    createTime: "create_time", // 触发时间
    tId: "tid", // 埋点平台事件注册id
    url: "url", // [ 埋点页面地址 | 图片链接地址 | 外部链接地址 ]
    page: "page", // 埋点所在页面 页面名称
    goodsId: "goods_id", // 商品ID
    result: "result", // 操作结果
    text: "text", // 文本信息
    memberId: "member_id", // 用户ID
}
