import 'intersection-observer'
import { send, getSendParam } from '@/utils/track/sendData'
import { eventType, eventCommonFields } from '@/utils/track/eventTypes'

IntersectionObserver.prototype.POLL_INTERVAL = 100; // 节流时间
// IntersectionObserver.prototype["USE_MUTATION_OBSERVER"] = false; // 非全局生效

/**
 * 页面元素曝光
 */
export default class Exposure {
    _observer = null;

    static init() {
        // 实例化监听
        this._observer = new IntersectionObserver(function(entries, observer) {
            entries.forEach((entry) => {
                // 出现在视窗中
                if (entry.isIntersecting) {
                    // console.log('埋点曝光',entry.target.param, entry)
                    let param = JSON.parse(JSON.stringify(entry.target.param || {}))
                    param = getSendParam(param, eventType.exposure);
                    param[eventCommonFields.distinguishId] = param.exposureDistinguish || "";

                    // 曝光之后取消观察
                    observer.unobserve(entry.target)
                    
                    // console.log("==Exposure==:", param);
                    delete param.exposureDistinguish
                    delete param.clickDistinguish
                    // 上报数据
                    send(param)
                }
            })
        })
    }
    /**
     * 给元素添加监听
     * @param {Element} entry 
     */
    static add (entry) {
        entry.el.param = typeof entry.value === "object" ? JSON.parse(JSON.stringify(entry.value)) : entry.value;
        if (typeof entry.value === "object" && entry.el.param.hasOwnProperty.call("clickDistinguish")){
            delete entry.el.param.clickDistinguish;
        }
        this._observer && this._observer.observe(entry.el)
    }
}
