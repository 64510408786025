import { eventType } from '@/utils/track/eventTypes'
import {send, getSendParam } from '@/utils/track/sendData'

/**
 * 手动上报点击事件
 * 
 * 需要等待结果的情况
 * @param {*} params 
 */
export function clickHandler(params) {
    let param = getSendParam(params, eventType.click);
    send(param)
}

/**
 * 手动上报页面曝光事件
 * 
 * @param {*} params 
 */
export function exposureHandler(params) {
    let param = getSendParam(params, eventType.exposure);
    send(param)
}

/**
 * 接受 空格、& 隔开格式的参数串 暂时只考虑1级的情况
 * @param {*} paramStr 
 * @param {*} split 
 * @returns 
 */
export function toParamObj (paramStr, split) {
    if (!paramStr) { return {}; }
    let param = {};
    let type = typeof (param);
    if (type === "string"){
        let paramList = [];
        if (paramStr.indexOf("&") !== -1){
            paramList = paramStr.split("&")
        } else {
            paramList = paramStr.split(" ")
        }
        paramList.map((str)=> {
            let arr = str.split("=");
            
            param[arr[0]] = arr[1];
        })
    }
    return paramStr;
}


/**
 * 参数对象转为字符串
 * @param {*} param 
 * @param {*} key 
 * @param {*} encode 
 * @returns 
 */
const toParamStr = (param, key, encode) => {
    if (param==null) { return ''; }
    var paramStr = '';
    var t = typeof (param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        paramStr += ' ' + key + '=' + ((encode==null||encode) ? encodeURIComponent(param) : param);
    } else {
        for (var i in param) {
            var k = key == null ? i : key + (param instanceof Array ? '[]' : '.' + i);
            paramStr += toParamStr(param[i], k, encode);
        }
    }
    return paramStr;
}
