import { send, getSendParam } from '@/utils/track/sendData'
import { eventType, eventCommonFields } from '@/utils/track/eventTypes'

const exactMatch = (modifiers, vals) => {
    const keys = Object.keys(modifiers);
    return keys.length === vals.length && keys.every(e => vals.includes(e))
}
/**
 * 页面浏览
 */
export default class Browse {
    constructor(){

    }
    
    static add({ el, value, context, modifiers}) {
        const browse = new Browse();
        
        if(exactMatch(modifiers, ['watch'])) {
            const exp = Object.keys(value).shift();
            el.$unwatch = context.$watch(() => context[exp], (newVal, oldVal)=>{
                newVal !== oldVal && el.contains(this.target);
                this.target = null; // 释放当前操作的watcher
                browse.doSendData(newVal)
            })
        } else {
            browse.doSendData(value)
        }
        
    }

    doSendData(value){
        let param = JSON.parse(JSON.stringify(value || {}))// entry.el.attributes['track-params'].value

        param = getSendParam(param, eventType.browse);
        param[eventCommonFields.distinguishId] = param.browseDistinguish || ""
        console.log("==Browse==", param)
        delete param.browseDistinguish;
        send(param)
    }
}
