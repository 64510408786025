import { send, getSendParam } from '@/utils/track/sendData'
import { eventType, eventCommonFields } from '@/utils/track/eventTypes'

/**
 * 元素点击事件
 */
export default class ClickEvent {
    constructor(){
    }
    static add(entry) {
        let param = entry.value || {};// entry.el.attributes['track-params'].value
        
        // param = getSendParam(param, (param.back ? eventType.back: eventType.click));
        param = getSendParam(param, eventType.click);
        entry.el.$clickListener = function () {
            let data = JSON.parse(JSON.stringify(param))
            try {
                // value 必须为json对象字符串
                let value = entry.el.attributes['data-track'] && entry.el.attributes['data-track'].value;
                if (value && typeof value === "string") {
                    let valueObj = JSON.parse(value);
                    data = typeof valueObj === "object" ? Object.assign(data, valueObj) : data
                }
            } catch (e) {
                console.error(e)
            }

            data[eventCommonFields.distinguishId] = data.clickDistinguish || "";

            // console.log("==click==", param)
            delete data.clickDistinguish;
            delete data.exposureDistinguish;
            delete data.back;
            send(data)
        }
        entry.el.addEventListener('click', entry.el.$clickListener)
    }
    static removeListener(el){
        if (el.$clickListener) {
            el.removeEventListener("click", el.$clickListener)
            el.$clickListener = null;
        }
    }
}


